<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 证书信息 </template>
      <template #input>
        <a-input placeholder="请输入姓名" v-model="name" />
        <a-input placeholder="请输入手机号" v-model="mobile" />
        <a-input placeholder="请输入机构名称" v-model="orgName" />
        <a-input placeholder="请输入身份证号" v-model="idcard" />
        <a-range-picker
          @change="onBuyTime"
          v-model="timeId"
          :placeholder="['开始日期', '结束日期']"
          format="YYYY-MM-DD HH:mm:ss"
        />
        <!-- <a-select v-model="timeId" placeholder="请选择考试日期/场次" allowClear>
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="null"> 全部 </a-select-option>

          <a-select-option
            v-for="(item, index) in testData"
            :key="index"
            :value="item.timeId"
            >{{ item.examDate }}{{ item.examDateTime }}</a-select-option
          >
        </a-select> -->
        <a-select
          v-model="isConfirm"
          placeholder="请选择信息确认状态"
          allowClear
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="null"> 全部 </a-select-option>

          <a-select-option :value="0">未确认</a-select-option>
          <a-select-option :value="1">已确认</a-select-option>
        </a-select>
        <a-select v-model="signupType" placeholder="请选择报名方式" allowClear>
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="null"> 全部 </a-select-option>
          <a-select-option :value="3">个人报名</a-select-option>
          <a-select-option :value="1">机构统一报名</a-select-option>
          <a-select-option :value="2">机构个人报名</a-select-option>
        </a-select>
        <a-button
          type="primary"
          class="btn"
          :loading="loading"
          @click="search()"
          >搜索</a-button
        >
        <a-button type="primary" class="btn" @click="selpeople()"
          >短信提醒</a-button
        >
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane
          :key="item.configId"
          v-for="item in tabData"
          :tab="item.abbreviation"
        ></a-tab-pane>
      </a-tabs>
      <a-table
        class="table-template"
        :scroll="{ x: true }"
        :rowKey="(item) => item.examineeId"
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: select,
          onSelectAll: selectAll,
          columnTitle: '选择',
        }"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="footer">
          <a-checkbox
            @change="onChange"
            :checked="isChecked"
            class="allSellect"
          >
            全选
          </a-checkbox>
        </template>
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <template slot="mobile" slot-scope="text, item">
        {{ item.mobile ? $encryption('empty','phone',item.mobile) : "--" }}
        </template>
        <template slot="idcard" slot-scope="text, item">
        {{ item.idcard ? $encryption('empty','idCard',item.idcard) : "--" }}
        </template>
        <template slot="signupOrgId" slot-scope="item, row">
          <div style="text-align: center">
            {{ rettrue(row.orgId, row.signupOrgId) }}
          </div>
        </template>
        <template slot="classname" slot-scope="item, row">
          <div style="text-align: center">
            {{ row.signupOrgId ? row.orgName : "-" }}
          </div>
        </template>

        <template slot="operation" slot-scope="item, row">
          <div class="btn_router_link">
            <a @click="goPage(row.examineeId, row.isConfirm)">修改</a>
            <span v-if="row.isConfirm == 0">|</span>
            <a v-if="row.isConfirm == 0" @click="remind(row.examineeId)"
              >短信提醒</a
            >
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
    fixed: "left",
  },
  // {
  //   title: "课程名称",
  //   align:'center',
  //   dataIndex: "code1"
  // },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
    scopedSlots: { customRender: "mobile" },
  },
  {
    title: "身份证号",
    align: "center",
    dataIndex: "idcard",
    scopedSlots: { customRender: "idcard" },
  },
  {
    title: "考试方式",
    align: "center",
    dataIndex: "signupOrgId",
    scopedSlots: { customRender: "signupOrgId" },
  },
  {
    title: "机构分配",
    align: "center",
    dataIndex: "classname",
    scopedSlots: { customRender: "classname" },
  },
  {
    title: "学历",
    align: "center",
    dataIndex: "education",
  },
  {
    title: "信息确认状态",
    align: "center",
    dataIndex: "isConfirmText",
  },
  // {
  //   title: "信息确认方",
  //   align: "center",
  //   dataIndex: "confirmeName",
  // },
  {
    title: "职称/职务",
    align: "center",
    dataIndex: "post",
  },
  {
    title: "工作单位",
    align: "center",
    dataIndex: "workUnit",
  },
  {
    title: "照片提交方式",
    align: "center",
    dataIndex: "picturesType",
  },
  {
    title: "是否付款",
    align: "center",
    dataIndex: "isPayText",
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "operation" },
    fixed: "right",
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      tabData: [], // 签页数据
      configId: 1,
      testData: [], // 场次数据
      isChecked: false,
      selectedRowKeys: [],
      name: "",
      mobile: "",
      idcard: "",
      isConfirm: undefined, // 是否确认 1.是 0.否
      timeId: null, // 场次id

      // 时间
      startTime: null, //
      endTime: null, //

      orgName: null, //
      signupType: null, // 报名方式 1.机构统一 2.个人
    };
  },
  // 事件处理器
  methods: {
    // 返回正确的考试方式
    rettrue(e, r) {
      let txt = "";
      if (r) {
        if (e) {
          txt = "机构统一报名";
        } else {
          txt = "机构个人报名";
        }
      } else {
        txt = "个人报名";
      }
      return txt;
    },
    // 选择时间
    onBuyTime(date, dateString) {
      this.startTime = dateString[0]; // 开始日期
      this.endTime = dateString[1]; // 结束日期
    },
    // 证书生成
    goPage(e, r) {
      this.$router.push({
        path: "/admin/Certificate/CertificateModify",
        query: { examineeId: e, isConfirm: r },
      });
    },
    select(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      if (this.selectedRowKeys.length == this.tableData.length) {
        this.isChecked = true;
      } else {
        this.isChecked = false;
      }
      // console.log(selectedRowKeys);
    },
    selectAll(selectedRows) {
      this.selectedRows = selectedRows;
    },
    //全选
    onChange(e) {
      if (e.target.checked == true) {
        this.isChecked = true;
        let id = this.tableData.map((item) => {
          return item.examineeId;
        });
        this.selectedRowKeys = id;
      } else {
        this.isChecked = false;
        this.selectedRowKeys = [];
      }
      // console.log(`checked = ${e.target.checked}`);
    },
    // tab切换
    callback(e) {
      this.configId = e;
      this.pageNumber = 1;
      this.selectedRowKeys = [];
      this.getdataList();
      this.gettestData();
    },
    // 分页
    onPage(e) {
      // console.log(e);
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.selectedRowKeys = [];

      this.getdataList();
    },
    // 搜索
    search() {
      this.pageNumber = 1;
      this.pageSize = 10;
      this.selectedRowKeys = [];

      this.getdataList();
    },
    // 获取数据
    getdataList() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/exam/certif/certif-manage/confirm-list",
        method: "get",
        params: {
          current: this.pageNumber,
          size: this.pageSize,
          name: this.name,
          mobile: this.mobile,
          idcard: this.idcard,
          configId: this.configId,
          isConfirm: this.isConfirm, // 是否确认 1.是 0.否
          // timeId: this.isConfirm, // 场次id
          timeStartDate: this.startTime, //开始
          timeEndDate: this.endTime, //结束
          signupType: this.signupType,
          orgName: this.orgName,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 获取课程数据
    gettablist() {
      this.$ajax({
        url: "/hxclass-management/exam/config/exam/tab/list",
        method: "get",
        // params: {
        //   current: this.pageNumber,
        //   size: this.pageSize,
        //   courseName: this.courseName,
        // },
      }).then((res) => {
        this.tabData = res.data;
        this.configId = res.data[0].configId;
        this.getdataList();
        this.gettestData();
      });
    },

    // 获取考试场次数据
    gettestData() {
      this.$ajax({
        url: "/hxclass-management/exam/certif/time-list",
        method: "get",
        params: {
          current: this.pageNumber,
          size: this.pageSize,
          configId: this.configId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.testData = res.data.records;
          this.totals = res.data.total;
        }
      });
    },
    // 选择短信发送人群
    selpeople() {
      if (this.selectedRowKeys.length < 1) {
        this.$message.warning("请选择用户");
        return;
      }
      let arr = this.selectedRowKeys.join(",");
      this.remind(arr);
    },
    // 短信提醒
    remind(e) {
      let _this = this;
      this.$confirm({
        title: "确定提醒?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url:
                "/hxclass-management/exam/certif/certif-manage/sms-remindelr?examineeId=" +
                e,
              method: "PUT",
              params: {
                // examineeId: e,
              },
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("操作成功");
              } else {
                _this.$message.error("操作失败");
              }
            });
        },
        onCancel() {},
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.gettablist();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
/deep/.right-box {
  padding-bottom: 5px !important;
}
.modalDiv {
  > p {
    font-weight: 500;
    color: #333333;
  }
  .code-item {
    > span {
      display: inline-block;
      min-width: 100px;
      margin: 5px 0;
      margin-right: 15px;
    }
  }
}
</style>